import axios, { AxiosResponse } from 'axios';

const Api = axios.create({
    baseURL: "https://mail.squadytecnologia.com.br/",
});

interface EmailData {
    name: string;
    email: string;
    phone: string;
    position: string;
    company: string;
    investment: string;
    met: string;
    ano_atual: string;
}

export async function sendEmail(data: EmailData): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    const emailData = {
        from_email: "noreply@squadytecnologia.com.br",
        template_id: "f0622748-905e-42d3-a910-2f0ecd4edd8e",
        context_template: {
            "%name%": data.name,
            "%email%": data.email,
            "%phone%": data.phone,
            "%position%": data.position,
            "%company%": data.company,
            "%investment%": data.investment,
            "%met%": data.met,
            "%ano_atual%": data.ano_atual,
        },
        recipient_list: ["comercial@forrisk.com.br"],
        subject: "Contato via site",
    };
    formData.append("data", JSON.stringify(emailData));

    const options = {
        headers: {
            "Content-Type": "multipart/form-data; boundary=---011000010111000001101001",
            Authorization: "Bearer ae87f2508c901d188bbddbc1dd090f5249f7c6e0",
        }
    };

    try {
        const response: AxiosResponse<any> = await Api.post("send/", formData, options);
        return response;
    } catch (error) {
        throw error;
    }
}
