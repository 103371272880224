import React, { useEffect, useState } from 'react';
import "./styles.css";
import Field from "./../components/Field";
import DropdownList from "../components/DropdownList";
import { useMyContext } from './MyContextProvider';
import { sendEmail } from '../SendEmail/sendEmail';

interface FormData {
  name: string;
  email: string;
  position: string;
  phone: string;
  company: string;
  investment: string;
  met: string;
}

const Sidebar: React.FC = () => {
  const { isOpen, updateisOpen } = useMyContext();
  const [formErrors, setFormErrors] = useState<Partial<FormData>>({});
  const [resetDropdown, setResetDropdown] = useState<boolean>(false);
  const [shouldCloseForm, setShouldCloseForm] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);
  const [isTextType, setIsTextType] = useState(true);
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    position: '',
    phone: '',
    company: '',
    investment: '',
    met: '',
  });

  //fecha formulário quando clica fora dele
  useEffect(() => {

    const handleOutsideClick = (e: MouseEvent) => {
      const sidebar = document.querySelector('.sidebar');
      if (sidebar && !sidebar.contains(e.target as Node) && !shouldCloseForm) {
        updateisOpen(false);
        setShouldCloseForm(true);
        setShouldCloseForm(false);
        setTimeout(() => {
        }, 100);
      } else {
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [shouldCloseForm, updateisOpen]);

  const updateFormData = (key: keyof FormData, value: string) => {
    setFormData({ ...formData, [key]: value });
    setFormErrors({ ...formErrors, [key]: '' });
    validateField(key, value);
  };

  const resetForm = () => {
    setFormData({
      name: '',
      email: '',
      position: '',
      phone: '',
      company: '',
      investment: '',
      met: '',
    });
    setFormErrors({});
    setResetDropdown(true);
    setIsTextType(false);
  };

  const resetDropdownComplete = () => {
    setResetDropdown(false);
  };

  const validateField = (key: keyof FormData, value: string) => {
    if (key === 'email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        setFormErrors({ ...formErrors, [key]: 'Formato de e-mail inválido' });
      } else {
        setFormErrors({ ...formErrors, [key]: '' });
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    Object.keys(formData).forEach((key) => {
      validateField(key as keyof FormData, formData[key as keyof FormData]);
    });

    const hasErrors = Object.values(formErrors).some((error) => error !== '');
    const year = new Date();

    if (hasErrors) {
      return;
    } else {
      try {
        await sendEmail({
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          position: formData.position,
          company: formData.company,
          investment: formData.investment,
          met: formData.met,
          ano_atual: JSON.stringify(year.getFullYear()),
        });

        setIsEmailSent(true);
        setTimeout(() => {
          setIsEmailSent(false)
        }, 4000)

      } catch (error) {
        console.error(error);
      }
      resetForm();

    }
  };

  const handleDropdownChange = (value: string | undefined) => {
    updateFormData('investment', value || '');
    resetDropdownComplete();
  };

  const toggleSidebar = () => {
    updateisOpen(!isOpen);
  };

  const companySize = [
    "Até R$ 25.000",
    "R$ 25.000 - R$ 50.000",
    "R$ 50.000 - R$ 75.000",
    "R$ 75.000 - R$ 100.000",
    "R$ 100.000 +",
  ];

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = isTextType ? e.target.value.replace(/[^a-zA-Z\s]/g, '') : e.target.value;
    updateFormData('met', inputValue);
  };

  return (
    <div>
      <div className={`sidebar ${isOpen ? "open" : ""}`}>
        <button className="toggle-button-exit" onClick={toggleSidebar}>
          X
        </button>
        {isOpen && (
          <div className="sidebar-content">
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  alignItems: "start",
                }}
              >
                <h2>Estamos prontos para te atender</h2>
                <p className="quote">
                  Vamos elaborar um plano de ação e resolver seu desafio. Por
                  favor, compartilhe seus dados abaixo e entraremos em contato
                  com você o mais breve possível. Venha tomar um café conosco!
                </p>
                <div className="contactContainer">
                  <div id="contactUs">
                    <form onSubmit={handleSubmit}>
                      <div className="formContainer">
                        <Field
                          required
                          type='text'
                          label="Seu nome*"
                          placeholder='João da Silva'
                          value={formData.name}
                          onChange={(value) => {
                            updateFormData('name', value);
                          }}
                        />
                        <Field
                          value={formData.email}
                          required
                          type="email"
                          placeholder="joão.silva@empresa.com"
                          label="E-mail de trabalho*"
                          onBlur={() => validateField('email', formData.email)}
                          onChange={(value) => {
                            updateFormData('email', value);
                          }}
                          errorMessage={formErrors.email}
                        />
                      </div>
                      <div className="formContainer">
                        <Field
                          required
                          label="Cargo*"
                          value={formData.position}
                          placeholder='Diretor comercial'
                          onChange={(value) => {
                            updateFormData('position', value)
                          }}
                        />
                        <Field
                          type='tel'
                          required
                          value={formData.phone}
                          placeholder="(XX)90000-0000"
                          label="DDD + Número de celular*"
                          onChange={(value) => {
                            updateFormData('phone', value);
                          }}
                        />
                      </div>
                      <div className="formContainer">
                        <Field
                          required
                          label="Nome da empresa*"
                          value={formData.company}
                          placeholder='Sua empresa Ltda'
                          onChange={(value) => {
                            updateFormData('company', value)
                          }}
                        />
                        <DropdownList
                          label="investimento disponível*"
                          options={companySize}
                          reset={resetDropdown}
                          onChange={handleDropdownChange}
                        />
                      </div>
                      <div id="contactUsLines" className="formContainer form_messeng">
                        <label htmlFor="met">
                          Como você ficou sabendo sobre nós?*
                        </label>
                        <input
                          value={formData.met}
                          required
                          placeholder="Mensagem"
                          style={{ height: "5rem" }}
                          onChange={handleInputChange}
                        >
                        </input>
                      </div>
                      <button className="form-button" type="submit">Enviar</button>
                    </form>
                    {isEmailSent && (
                      <p className="success-message">Formulário enviado com sucesso!</p>
                    )}
                  </div>

                  <div id="networks">
                    <ul className='networks_list'>
                      <li>
                        <a href="https://www.instagram.com/sqad.dev" target="_blank" rel="noopener noreferrer">
                          <img
                            className="network"
                            src="/images/instagramLogo.png"
                            alt='Logo do instagram'
                          />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/company/sqad/" target="_blank" rel="noopener noreferrer">
                          <img className="network" src="/images/linkedin.png" alt='logo do linkedin' />
                        </a>
                      </li>
                      <li>
                        <a href="mailto:comercial@forrisk.com.br" target="_blank" rel="noopener noreferrer">
                          <img className="network" src="/images/gmailLogo.png" alt='logo do gmail' />
                        </a>
                      </li>
                      {/*<li> <a
                        href="https://api.whatsapp.com/send/?phone=5511958279760&text=Oi"
                        target="_blank" rel="noopener noreferrer"
                      >
                        <img className="network" src="/images/whatsapp.png" alt='logo do WhatsApp' />
                      </a></li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;